import React, { useEffect, useState } from "react";
import { Layout, PasscodeForm, Text, Title } from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import {
	AuthenticatorI,
	PageI,
	errorI,
} from "../../components/ComponentInterface";
import { useTriggeredAuthenticatorMethod } from "../../services";

const SignInOTPPage = ({
	successFunc,
	goBackLink,
	otherRoutes: { apiType = "post" } = {},
}: PageI) => {
	const { useAuthenticatorMethod: authenticatorMethod } =
		useTriggeredAuthenticatorMethod();
	const [authMethod, setAuthMethod] = useState<AuthenticatorI>({});
	const [errorObj, setErrorObj] = useState<errorI>({ error: "" });
	const error500 = () => setErrorObj({ ...errorObj, error: "error-500-1" });

	const getDescriptionKey = (method: string | undefined) => {
		switch (method) {
			case "TOTP":
				return "one-time-password-totp-description";
			case "SMS":
				return "one-time-password-sms-description";
			default:
				return "one-time-password-description";
		}
	};

	// Authenticator Method API Call
	useEffect(() => {
		const getAuthMethod = async () => {
			try {
				if (!errorObj.error) {
					const { response } = await authenticatorMethod();
					if (response.isSuccess) {
						const data = response.data;
						setAuthMethod(data);
					} else {
						error500();
					}
				}
			} catch (e) {
				console.log("e", e);
				error500();
			}
		};

		getAuthMethod();
		// eslint-disable-next-line
	}, []);

	return (
		<Layout
			header
			footer
			title={i18n.t("one-time-password-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="one-time-password-form"
		>
			<Title title={i18n.t("one-time-password-title")} />
			<Text className="lead-text">{parse(i18n.t(getDescriptionKey(authMethod.method)))}</Text>
			<PasscodeForm
				successFunc={successFunc}
				apiType={apiType}
				twoFaMethod={authMethod.method}
			/>
		</Layout>
	);
};

export default SignInOTPPage;
