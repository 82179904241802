import { useHistory } from 'react-router-dom'
import { updateAccountCreation, updateProfileState, updateTermsAccepted, updateToken, useContext } from '../../Context'
import ROUTES from '../../routes/common/constants'
import { useTriggeredLogout } from '../../services'

export default function useHandleLogout() {
  const { useLogout: logoutRequest } = useTriggeredLogout()

  const history = useHistory()

  const {
    state: {
      config: { pei_portal_link, phrsaml_logout_url },
    },
    dispatch,
  } = useContext()

  const cleanUpUserSessionInfo = () => {
    dispatch(updateToken(''))
    dispatch(updateProfileState({}))
    dispatch(updateAccountCreation({}))
    dispatch(updateTermsAccepted([]))
    window.history.replaceState('state', '')
    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  const handleLogout = async () => {
    await logoutRequest()
    cleanUpUserSessionInfo()
  }

  const logoutMyPei = async () => {
    await handleLogout()
    const logoutUrl = `${pei_portal_link}?logout=true`
    window.location.href = logoutUrl
  }

  const logoutPhrsaml = async () => {
    await handleLogout()
    const logoutUrl = `${phrsaml_logout_url}`
    window.location.href = logoutUrl
  }

  const redirectLandingPage = async () => {
    await handleLogout()
    history.push(ROUTES.LandingPage)
  }

  return { logoutMyPei, logoutPhrsaml, redirectLandingPage }
}
