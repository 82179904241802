import parse from 'html-react-parser'
import React, { FC } from 'react'
import { ReactComponent as ArrowIcon } from '../../asset/solar_arrow-up-linear.svg'
import { Card } from '../../components'
import { useContext } from '../../Context'
import i18n from '../../i18n'

type AuthSelectorProps = {
  totpRoute?: string
  smsRoute?: string
  emailRoute?: string
}

const AuthSelector: FC<AuthSelectorProps> = ({ totpRoute, smsRoute, emailRoute }) => {
  const {
    state: { config },
  } = useContext()
  const smsEnabled = config?.['2fa_options']?.sms ?? false
  const emailEnabled = config?.['2fa_options']?.email ?? false
  const authenticatorEnabled = config?.['2fa_options']?.authenticator ?? false
  return (
    <>
      <div className="options-container">
        <div className="card-container">
          {authenticatorEnabled && (
            <Card
              icon={<ArrowIcon />}
              title={i18n.t('use-auth-app')}
              description={i18n.t('use-auth-app-description')}
              url={totpRoute}
              reminder={i18n.t('use-auth-app-body-reminder')}
              dataTestId="card-auth-app"
            />
          )}
          {smsEnabled && <Card icon={<ArrowIcon />} title={i18n.t('use-sms-auth')} description={i18n.t('use-sms-auth-description')} url={smsRoute} dataTestId="card-auth-text" />}
        </div>
        {emailEnabled && (
          <div className="card-container">
            <div className="alternative-option-container">
              {(smsEnabled || authenticatorEnabled) && <p className="alternative-option">{parse(i18n.t('alternative-option'))}</p>}
              <Card
                className="outline"
                icon={<ArrowIcon />}
                title={i18n.t('use-email-auth')}
                description={i18n.t('use-email-auth-description')}
                url={emailRoute}
                dataTestId="card-auth-email"
              />
            </div>
          </div>
        )}
      </div>
      {!(authenticatorEnabled || smsEnabled || emailEnabled) && <div className="">{parse(i18n.t('error-500-1'))}</div>}
    </>
  )
}

export default AuthSelector
